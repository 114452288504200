html,
body {
    height: 100%;

    #root {
        height: 100%;
    }
}

body {
    background-color: black !important;
}

.slick-slider.hero-slider {
    .slick-dots {
        bottom: -75px;

        li {
            button {
                &::before {
                    color: rgba(196, 196, 196, 0.5) !important;
                    font-size: 16px !important;
                }
            }
            
            &.slick-active {
                button {
                    &::before {
                        color: rgba(196, 196, 196, 1) !important;
                    }
                }
            }
        }
    }
}

.sticky-wrapper {
    position: relative;
    z-index: 9999 !important;
}

.infinity-carousel {
    margin: 0 auto;
    margin-top: 6.25rem;
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    .infinity-carousel--image {
        width: 9966px;
        // background-size: cover !important;
        background-color: royalblue;
        background-position: left !important;
        background-attachment: scroll;
        background-repeat: repeat-x !important;
        position: absolute;
        top: 0;
        left: 0;
        animation: moveSlideshow 60s linear infinite;
    }
}

@keyframes moveSlideshow {
    100% { 
      transform: translateX(-66.6666%);  
    }
  }